import React from 'react'
import * as S from './styled';
import logo from '../../images/logo02.png';


const QuizLayout = ({children}) => (
    <S.Layout>
        <S.Logo>
            <img src={logo} alt="logo"/>
        </S.Logo>
        <S.Body>
            {children}
        </S.Body>
    </S.Layout>
)

export default QuizLayout;