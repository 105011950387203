import React, { useContext, useEffect } from "react"
import Step from "./Step"
import Navigation from "./Navigation"
import { Context as FormContext } from "../../context/FormContext"

import * as S from "./styled"

// const initialValues

const QuizForm = ({ navigation, children }) => {
  const { state, setSteps } = useContext(FormContext)

  //Define number os steps
  useEffect(() => {
    setSteps(React.Children.count(children))
  }, [children])

  return (
    <S.FormWrapper>
      <S.Form>
        {
          //render only step selected in state
          React.Children.map(children, (child, i) => {
            return state.step === i ? (
              <Step title={child.props.title} children={child} />
            ) : null
          })
        }
      </S.Form>
      {
        state.step < state.steps - 1 && state.step > 0 ? (
          <Navigation navStyle={navigation} />
        ) : null
        // <FormButton text="Refazer" type="restart" onClick={}
      }
    </S.FormWrapper>
  )
}

export default QuizForm
