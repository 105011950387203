import React, { useContext } from 'react';
import * as S from './styled';
import { Context as FormContext } from '../../context/FormContext';

const Bullet = ({highlight}) => (
    <S.Bullet highlight={highlight} />
)
const Navigation = ({ navStyle }) => {
    const { state, prevStep} = useContext(FormContext);
    let bullets = []

    for (let i=0; i<state.steps; i++) {    
        bullets.push(<Bullet key={i} highlight={i===state.step}/>)
    }
    
    return (
        <S.Navigation>
            <S.PrevButton onClick={prevStep} disabled={state.step === 0} />
            { navStyle !== 'simple' && bullets}
            {/* <S.NextButton onClick={prev} disabled={prev === 1} /> */}
        </S.Navigation>
    )
}

export default Navigation;