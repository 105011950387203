import styled from 'styled-components';
import media from 'styled-media-query';
import {ArrowCircleLeft} from '@styled-icons/heroicons-outline/ArrowCircleLeft';

export const FormWrapper = styled.section`
    display: flex;
    flex-direction: column;
`

export const Form = styled.form`
    display: flex;
    flex-direction: row;
`

export const FormInput = styled.div`
    position: relative;
    display: inline-block;
    margin: 5px 0;

    .icon {
        height: 25px;
        width: 25px;
        color: var(--orange);
        margin-right: 10px;
    }

    input {
        line-height: 25px;
        background: transparent;
        border: none;
        font-size: 18px;
        border-bottom: 1px solid var(--orange);
        padding: 5px 45px ;
        color: white;
        border-radius: 0;
        width: 250px;
    }

    input::placeholder {
        color: #bfbfbf;
    }

    input:hover, input:active {
        border: 1px solid var(--orange);
    }

    button {
        margin: 2rem auto;
        padding: 1rem 2rem;
        border-radius: 20px;
        color: white;
        width: 250px;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: 500;
        border: 1px solid var(--pink);
        background-color: transparent;
        cursor: pointer;
        box-shadow: 2px 2px 14px rgb(0 0 0 / 40%);
        transition: background-color 1s;
    }

    button:hover {
        background-color: var(--pink);//linear-gradient(90deg, #0284fe 0%, #0df5d2 100%);
    }
`

export const PrevButton = styled(ArrowCircleLeft)`
    color: var(--orange);
    cursor: pointer;
    height: 30px;
    width: 30px;
    margin: 5px 1rem;

    &:hover, &:focus {
        transform: scale(1.1);
    }

    display: ${props => props.disabled && 'none'};
`


export const Button = styled.button`
`

export const IconText = styled.span`

`

export const Navigation = styled.section`
    display: flex;
    flex-direction: row;
    margin: 1rem auto;
    align-items: center;
`;

export const Bullet = styled.div`
    margin: 5px 1rem;
    border-radius: 50%;
    width: 12px;
    height: 12px;

    background: ${props => props.highlight ? 'var(--orange)' : 'rgba(var(--orangeRGB), 0.4)'};
    transform: ${props => props.highlight && 'scale(1.5)'};

`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 0 4rem;

`

export const Header = styled.section`
    /* grid-row: 1/2;
    grid-column: 2/-2; */
    margin: 1rem auto;
    h2 { color: white; }
    text-align: center;       

    ${media.lessThan("medium")`
        margin: 1rem;
    `}
`

export const FormButton = styled.div`
    position: relative;
    display: inline-block;
    margin: 5px 0;
    align-self: center;

    button {
        margin: 2rem auto;
        padding: 1rem 2rem;
        border-radius: 20px;
        color: white;
        width: 250px;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: 500;
        border: 1px solid var(--pink);
        background-color: transparent;
        cursor: pointer;
        box-shadow: 2px 2px 14px rgb(0 0 0 / 40%);
        transition: background-color 1s;
    }

    button:hover {
        background-color: var(--pink);//linear-gradient(90deg, #0284fe 0%, #0df5d2 100%);
    }

    .icon {
        height: 45px;
        width: 45px;
        color: var(--orange);
        margin-left: 10px;
    }
`