import React from 'react'; 
import * as S from './styled'; 
import { ArrowRight } from '@styled-icons/material/ArrowRight';
import { Calculator } from '@styled-icons/ionicons-solid/Calculator';
import { RestartAlt } from '@styled-icons/material/RestartAlt';
import { FileDownload } from '@styled-icons/material/FileDownload';

const FormButton = ({text, onClick, type}) => ( 
    <S.FormButton>
        <button className="primary" type={type} onClick={onClick}>
            {text}  
            {type === 'next' && <ArrowRight className="icon"/>}
            {type === 'submit' && <Calculator className="icon" />}
            {type === 'restart' && <RestartAlt className="icon" />}
            {type === 'download' && <FileDownload className="icon" />}
        </button>
    </S.FormButton>
);

export default FormButton;
