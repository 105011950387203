import React from 'react';
import createDataContext from './createDataContext';

const formReducer = (state, action) => {
    switch(action.type) {
        case 'set_steps': 
            return {...state, steps: action.steps}
        case 'add': 
            return { ...state, values: {...state.values, [action.key]: action.value}}
            //state.map((item) => item.key === action.key ? action.value : item );
        case 'next':
            return {...state, step: (state.step < (state.steps - 1)) ? state.step + 1 : state.step }
        case 'prev':
            return {...state, step: (state.step === 0) ? state.step : state.step - 1 }
        default:
            return state;
    }
}

const setSteps = dispatch => (steps) => dispatch({ type: 'set_steps', steps});

const nextStep = dispatch => () => dispatch({ type: 'next' });

const prevStep = dispatch => () => dispatch({ type: 'prev' });

const addValue = dispatch => (key, value) => dispatch({ type: 'add', key, value });


export const { Context, Provider } = createDataContext(
    formReducer, //state
    {
        addValue,
        nextStep,
        prevStep,
        setSteps
    }, 
    {
        steps: 0,
        step: 0,
        values: {}
    } //initialState
);