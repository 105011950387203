import React from "react"
import GlobalStyles from "../styles/global"
import QuizLayout from "../components/QuizLayout"
import Analise from "../components/Analise"
// import * as S from '../styles/quiz';
import { Provider as FormProvider } from "../context/FormContext"

const AnalisePage = () => {
  return (
    <QuizLayout>
      <GlobalStyles />
      <FormProvider>
        <Analise />
      </FormProvider>
    </QuizLayout>
  )
}

export default AnalisePage
