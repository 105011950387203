import React, { useEffect, useContext } from 'react';
import * as S from './styled';
import * as C from '../../styles/elements';
import { Context as FormContext } from '../../context/FormContext';

const Step = ({title, children}) => {
    const { state, addValue, nextStep } = useContext(FormContext);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    //injeta props em children
    const renderChildren = () => {
        return React.cloneElement(children, {
            state: state.values, 
            setValues: (values) => {
                for (var key in values) {
                    addValue(key, values[key]);
                }
                nextStep();
            }
        })
    }

    return (
        <S.Wrapper>
            <S.Header>
                <C.SubTitle style={{textTransform: 'uppercase'}}>{title}</C.SubTitle>
            </S.Header>
            {renderChildren()}                
        </S.Wrapper>
    )
}

export default Step;
